// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataChannelsClient = createAsyncThunk('appUsers/getAllDataChannelsClient', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/client/channels/all',  { params: { id} })
  return response.data
})

export const getDataChannelsClient = createAsyncThunk('appUsers/getDataChannelsClient', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/client/channels/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const addChannel = createAsyncThunk('appUsers/addChannel', async (channel, { dispatch, getState }) => {
  console.log(channel)
  
  await axios.post('https://apiv3.opentlv.com/api/v3/client/addchannel', channel)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataChannelsClient(getState().channelsClient.params))
  return channel
})

export const deleteChannel = createAsyncThunk('appUsers/deleteChannel', async (id, { dispatch, getState }) => {
  console.log("getState()")
  console.log(id)

  await axios.delete('https://apiv3.opentlv.com/api/v3/client/channel/delete', {params: {id}})
//  await dispatch(getData(getState().users.params))
    await dispatch(getDataChannelsClient(getState().channelsClient.params))

  return id
})
export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataChannelsClient.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataChannelsClient.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appClientsSlice.reducer
