// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataOption = createAsyncThunk('appUsers/getAllDataOption', async (id) => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/user/options/all', { params: { id} })
  return response.data
})

export const getDataOption = createAsyncThunk('appUsers/getDataOption', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/user/options/data', { params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const addUserToOption = createAsyncThunk('appUsers/addUserToOption', async (group, { getState }) => {
  //  option.client_id = getState()
    console.log("addPrivilgeToGroup")
    
    group.user_id = getState().users.selectedUser.id
    
    
    await axios.post('https://apiv3.opentlv.com/api/v3/user/addusertooption', group)
    //.wait dispatch(getGroupPrivilegesData(getState().groupPrivileges.params))
  
    //await d
  })


export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataOption.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataOption.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appUsersSlice.reducer
