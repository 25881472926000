// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataChannelCatalogs = createAsyncThunk('appUsers/getAllDataChannelCatalogs', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/channel/catalogs/all', { params: { id} })
  return response.data
})

export const getDataChannelCatalogs = createAsyncThunk('appUsers/getDataChannelCatalogs', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/channel/catalogs/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const addCatalog = createAsyncThunk('appUsers/addCatalog', async (catalog, { dispatch, getState }) => {

  //catalog.groupe_id = getState().channelCatalogs.selectedChannel.id_groupe
//  console.log('index')
 // console.log(catalog)

  //catalog.id_channel = id_channel

  await axios.post('https://apiv3.opentlv.com/api/v3/groupe/addcatalog', catalog)

  console.log(getState().channelCatalogs)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataChannelCatalogs(getState().channelCatalogs.params))
  return site
})

export const deleteCatalog = createAsyncThunk('appUsers/deleteCatalog', async (id, { dispatch, getState }) => {
  console.log("getState()")
  console.log(id)

  await axios.delete('https://apiv3.opentlv.com/api/v3/client/catalog/delete', {params: {id}})
//  await dispatch(getData(getState().users.params))
await dispatch(getDataChannelCatalogs(getState().channelCatalogs.params))

  return id
})

export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedChannel: null,
    selectedClient: null,
    selectedGroupe : null

  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataChannelCatalogs.fulfilled, (state, action) => {
        state.allData = action.payload.catalogs
        state.selectedChannel = action.payload.channel
        state.selectedClient = action.payload.client
        state.selectedGroupe = action.payload.groupe


      })
      .addCase(getDataChannelCatalogs.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appClientsSlice.reducer