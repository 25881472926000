// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataZones = createAsyncThunk('appUsers/getAllDataZones', async (id) => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/user/zones/all', { params: { id} })
  return response.data
})

export const getDataZones = createAsyncThunk('appUsers/getDataZones', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/user/zones/data', { params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const addUserToZone = createAsyncThunk('appUsers/addUserToZone', async (group, { getState }) => {
  //  option.client_id = getState()
    console.log("addPrivilgeToZone")
    
    group.user_id = getState().users.selectedUser.id
    
    
    await axios.post('https://apiv3.opentlv.com/api/v3/user/addusertozones', group)
    //.wait dispatch(getGroupPrivilegesData(getState().groupPrivileges.params))
  
    //await d
  })


export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataZones.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataZones.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appUsersSlice.reducer
