// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataZonesSite = createAsyncThunk('appUsers/getAllDataZonesSite', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/site/zones/all',  { params: { id} })
  return response.data
})

export const getDataZonesSite = createAsyncThunk('appUsers/getDataZonesSite', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/site/zones/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})
export const getAllDataZoneSite = createAsyncThunk('appUsers/getAllDataZoneSite', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/site/zone/all',  { params: { id} })
  return response.data
})

export const getDataZoneSite = createAsyncThunk('appUsers/getDataZoneSite', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/site/zone/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const addZone = createAsyncThunk('appUsers/addZone', async (site, { dispatch, getState }) => {

  console.log("add zeone")

  site.id_site = getState().clients.selectedSite.id
  
  console.log(getState().sitesClient)


  await axios.post('https://apiv3.opentlv.com/api/v3/site/zone/add', site)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataZonesSite(getState().zonesSite.params))
  return site
})


export const editZone = createAsyncThunk('appUsers/editZone', async (site, { dispatch, getState }) => {

  console.log("add zeone")

  site.id_site = getState().clients.selectedSite.id
  
  console.log(getState().sitesClient)


  await axios.post('https://apiv3.opentlv.com/api/v3/site/zone/edit', site)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataZonesSite(getState().zonesSite.params))
  return site
})
export const deleteZone = createAsyncThunk('appUsers/deleteZone', async (site, { dispatch, getState }) => {
  console.log('disptach')
  console.log(getState().zonesSite.params)
  console.log(site)
  
//  site.id_site = getState().clients.selectedSite.id
  
  //console.log(getState().sitesClient)


  await axios.delete('https://apiv3.opentlv.com/api/v3/site/zone/delete', { params: { site } })

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataZonesSite(getState().zonesSite.params))
  return site
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataZonesSite.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataZonesSite.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getAllDataZoneSite.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataZoneSite.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appClientsSlice.reducer
