// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'


export const getMedia = createAsyncThunk('appUsers/getMedia', async (id) => {
  const response = await axios.get('https://apiv3.opentlv.com/creator/media', {params: {id}})
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async (id) => {
  const response = await axios.get('https://apiv3.opentlv.com/creator/data', {params: {id}, responseType: 'blob'})
  return response.data
})

export const getListMedias = createAsyncThunk('appUsers/getMedias', async () => {
  const response = await axios.get('https://apiv3.opentlv.com/creator/list/medias')
  return response.data
})

export const getListData = createAsyncThunk('appUsers/getListData', async () => {
  const response = await axios.get('https://apiv3.opentlv.com/creator/list/data')
  return response.data
})
export const generate = createAsyncThunk('appUsers/generate', async (obj, { dispatch, rejectWithValue}) => {

  const response = await axios.post('https://apiv3.opentlv.com/creator/generate', obj, {})
  return response

})

export const preview = createAsyncThunk('appUsers/preview', async (obj, { dispatch, rejectWithValue}) => {

  const response = await axios.post('https://apiv3.opentlv.com/creator/preview', obj, {})
  return response

})
export const projetSave = createAsyncThunk('appUsers/projetSave', async (obj, { dispatch, rejectWithValue}) => {

  const response = await axios.post('https://apiv3.opentlv.com/creator/projet/save', obj, {})
  return response

})
export const addData = createAsyncThunk('appUsers/addData', async (obj, { dispatch, rejectWithValue}) => {
    const formData = new FormData()
    formData.append('file', obj.file) 
    try {
      console.log(obj)
    const response = await axios.post('https://apiv3.opentlv.com/creator/upload-data', formData, {})

    console.log(response)
    if (!response.data.success && !response.data.original) {

      console.log('la')
      console.log(response.data.success)

      return rejectWithValue(response.data)
  }
         await dispatch(getAllData(obj.channel_id))
        return response.data
  //  return response.data

} catch (error) {
  console.log('error')
  console.log(error)
  throw rejectWithValue(error.message)
}
  })
export const deleteClient = createAsyncThunk('appUsers/deleteClient', async (id, { dispatch, getState}) => {
  console.log("getState()")
  console.log(getState)

  await axios.delete('https://apiv3.opentlv.com/api/v3/clients/client/delete', {params: {id}})
//  await dispatch(getData(getState().users.params))
await dispatch(getDataClients(getState().groupes.params))

  return id
})
export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    listMedias: [],
    listData:[],
    selectedMedia:[],
    selectedData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getMedia.fulfilled, (state, action) => {
        state.selectedMedia = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.selectedData = action.payload
      })
      .addCase(getListMedias.fulfilled, (state, action) => {
        state.listMedias = action.payload
      })
      .addCase(getListData.fulfilled, (state, action) => {
        state.listData = action.payload
      })

  }
})

export default appClientsSlice.reducer
