// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataOptionsSite = createAsyncThunk('appUsers/getAllDataOptionsSite', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/site/options/all',  { params: { id} })
  return response.data
})

export const getDataOptionsSite = createAsyncThunk('appUsers/getDataOptionsSite', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/site/options/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})
export const addOptionToSite = createAsyncThunk('appUsers/addOptionToSite', async (option, { dispatch, getState }) => {
  //  option.client_id = getState()
    console.log("add Option to site")
    console.log(getState().clients.selectedSite)
    //option.site_id = getState().clientOptionSites.selectedOption.id
    option.site_id = getState().clients.selectedSite.id
  
   
    await axios.post('https://apiv3.opentlv.com/api/v3/client/addsitetooption', option)
   await dispatch(getDataOptionsSite(getState().sitesClient.params))
  
    //await d
  })
export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataOptionsSite.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataOptionsSite.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appClientsSlice.reducer
