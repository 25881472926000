// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appPlayers/getAllData', async () => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/players/monitoring/all')
  return response.data
})

export const getData = createAsyncThunk('appPlayers/getData', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/players/monitoring/data', {params})
  console.log(response.data)

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getPlayer = createAsyncThunk('appPlayers/getPlayer', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/player/user', { params: { id} })
  console.log(response.data)
  return response.data
})

export const addUser = createAsyncThunk('appPlayers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appPlayers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appPlayersSlice = createSlice({
  name: 'appPlayers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedPlayer: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getPlayer.fulfilled, (state, action) => {
        state.selectedPlayer = action.payload
      })
  }
})

export default appPlayersSlice.reducer
