// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataOptionsSites = createAsyncThunk('appUsers/getAllDataOptionsSites', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/options/all',  { params: { id} })
  return response.data
})

export const getDataOptionsSites = createAsyncThunk('appUsers/getDataOptionsSites', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/options/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})
export const addOptionToSite = createAsyncThunk('appUsers/addOptionToSite', async (option, { dispatch, getState }) => {
  //  option.client_id = getState()
   
    option.option_id = getState().sitesoptions.selectedOption.id
  
   
    await axios.post('https://apiv3.opentlv.com/api/v3/client/addsitetooption', option)
   await dispatch(getDataOptionsSites(getState().sitesClient.params))
  
    //await d
  })


export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedOption: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataOptionsSites.fulfilled, (state, action) => {
        console.log(action.payload)
        state.allData = action.payload.sites
        state.selectedOption = action.payload.option
        state.client = action.payload.client
        state.groupe = action.payload.groupe

      })
      .addCase(getDataOptionsSites.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appClientsSlice.reducer
