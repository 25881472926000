// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get('https://apiv3.opentlv.com/creator/data/all')
  return response.data
})

export const getDataFiles = createAsyncThunk('appUsers/getDataFiles', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/creator/data/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getGroupe = createAsyncThunk('appUsers/getGroupe', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/groupes/groupe', { params: { id} })
  console.log(response.data)
  return response.data
})

export const addData = createAsyncThunk('appUsers/addData', async (obj, { dispatch, getState, rejectWithValue}) => {
    const formData = new FormData()
    formData.append('file', obj.file) 
    try {
      console.log(obj)
    const response = await axios.post('https://apiv3.opentlv.com/creator/upload-data', formData, {})

    console.log(response)
    if (!response.data.success && !response.data.original) {

      console.log('la')
      console.log(response.data.success)

      return rejectWithValue(response.data)
  }
  await dispatch(getDataFiles(getState().creatorData.params))

        return response.data
  //  return response.data

} catch (error) {
  console.log('error')
  console.log(error)
  throw rejectWithValue(error.message)
}
  })
export const deleteXls = createAsyncThunk('appUsers/deleteXls', async (id, { dispatch, getState}) => {
  await axios.delete('https://apiv3.opentlv.com/creator/xls/delete', {params: {id}})
  await dispatch(getDataFiles(getState().creatorData.params))
  return id
})
export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataFiles.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appClientsSlice.reducer
