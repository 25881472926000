// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getMonitoringPlayer = createAsyncThunk('appUsers/getMonitoringPlayer', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/player/monitoring',  { params: { id} })
  return response.data
})
export const getMonitoringCommands = createAsyncThunk('appUsers/getMonitoringCommands', async params => {

    const response = await axios.get('https://apiv3.opentlv.com/api/v3/player/monitoring/commands',  { params: { params} })
    return response.data

  })

export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    commandResponse : []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getMonitoringPlayer.fulfilled, (state, action) => {
        state.data = action.payload
      })
  }
})

export default appClientsSlice.reducer
