// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/users/all')
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/users/data', { params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})


export const getAllDataGroup = createAsyncThunk('appUsers/getAllDataGroup', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/user/group/all', { params: { id} })
  return response.data
})

export const getDataGroup = createAsyncThunk('appUsers/getDataGroup', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/user/group/data', { params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})
export const getAllDataPrivileges = createAsyncThunk('appUsers/getAllDataPrivileges', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/user/sites/all', { params: { id} })
  return response.data
})

export const getDataPrivileges = createAsyncThunk('appUsers/getDataPrivileges', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/user/sites/data', { params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})
export const addUserToGroup = createAsyncThunk('appUsers/addUserToGroup', async (group, { dispatch, getState }) => {
  //  option.client_id = getState()

    if (group.group_id) {
      group.user_id =  getState().users.selectedUser.id
    } else {
      group.group_id = getState().groupPrivileges.selectedGroup.id
    }
    
    await axios.post('https://apiv3.opentlv.com/api/v3/users/addusertogroup', group)
    await dispatch(getUsersGroupPrivilegesData(getState().groupPrivileges.params))
  
    //await d
  })

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('https://apiv3.opentlv.com/api/v3/users/adduser', user)
  await dispatch(getData(getState().users.params))
  //await dispatch(getAllData())
  return user
})

export const ChangeUserPassword = createAsyncThunk('appUsers/ChangeUserPassword', async (user) => {
  await axios.post('https://apiv3.opentlv.com/api/v3/users/changepassword', user)
 // await dispatch(getData(getState().users.params))
  //await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('https://apiv3.opentlv.com/api/v3/users/delete', {params: {id}})
  await dispatch(getData(getState().users.params))
  return id
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataGroup.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataGroup.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appUsersSlice.reducer
