// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const addPlaylist = createAsyncThunk('appUsers/addPlaylist', async (playlist, { dispatch, getState }) => {

  await axios.post('https://apiv3.opentlv.com/api/v3/clients/addplaylist', playlist)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataClientsPlaylists(getState().playlists.params))
  return playlist
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})


export const getSlot = createAsyncThunk('appUsers/getSlot', async id => {
  console.log(id)
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/playlist/slot', { params: { id} })
  console.log(response.data)
  return response.data
})

export const getEditSlot = createAsyncThunk('appUsers/getEditSlot', async (id, { dispatch, getState }) => {
  console.log(id)
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/playlist/edit/slot', { params: { id} })
  console.log(response.data)

 // await dispatch(getSlot(id))

  return response.data
})

export const getDataSlot = createAsyncThunk('appUsers/getDataSlot', async id => {
  console.log(id)
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/playlist/slot/data', { params: { id} })
  console.log(response.data)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
  })

export const getAllDatasPlaylistSlot = createAsyncThunk('appUsers/getAllDatasPlaylistSlot', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/playlist/slots', { params: { id} })

  return response.data
})

export const addSlot = createAsyncThunk('appUsers/addSlot', async (playlist, { dispatch }) => {

  await axios.post('https://apiv3.opentlv.com/api/v3/playlist/addslot', playlist)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))

  await dispatch(getAllDatasPlaylistSlot(playlist.playlist))

  return playlist
})

export const editSlot = createAsyncThunk('appUsers/addSlot', async (slot, { dispatch }) => {

  await axios.post('https://apiv3.opentlv.com/api/v3/playlist/edit/slot', slot)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))

  
  console.log(slot)
  console.log("edit")

  await dispatch(getSlot(slot.slot.id))

  return slot
})
export const copySlot = createAsyncThunk('appUsers/copySlot', async (playlist, { dispatch, getState }) => {

  await axios.post('https://apiv3.opentlv.com/api/v3/playlist/copy/slot', playlist)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))

//  await dispatch(getAllDatasPlaylistSlot(playlist.playlist))
console.log("DEBUG PUSH")
console.log(playlist)
console.log(getState().slotsPlaylist)

await dispatch(getAllDatasPlaylistSlot(getState().slotsPlaylist.selectedPlaylist.id))

  return playlist
})

export const addOptionToSlot = createAsyncThunk('appUsers/addOptionToSlot', async (option, { dispatch, getState }) => {

  console.log(getState().slotsPlaylist.selectedSlot.id)
  console.log(option)

  await axios.post('https://apiv3.opentlv.com/api/v3/playlist/add/option/slot', {params: {option, slot_id: getState().slotsPlaylist.selectedSlot.id }})

  await dispatch(getSlot(getState().slotsPlaylist.selectedSlot.id))

//  await dispatch(getAllDatasPlaylistSlot(playlist.playlist))
  console.log("axios")
  return playlist
})
export const addMediaToSlot = createAsyncThunk('appUsers/addMediaToSlot', async (media, { dispatch, getState }) => {

  console.log(getState().slotsPlaylist.selectedSlot.id)
  console.log(media)

  await axios.post('https://apiv3.opentlv.com/api/v3/playlist/add/media/slot', {params: {media, slot_id: getState().slotsPlaylist.selectedSlot.id }})

  await dispatch(getSlot(getState().slotsPlaylist.selectedSlot.id))

//  await dispatch(getAllDatasPlaylistSlot(playlist.playlist))
  console.log("axios")
  return playlist
})
export const addPlaylistToSlot = createAsyncThunk('appUsers/addPlaylistToSlot', async (playlist, { dispatch, getState }) => {

  console.log(getState().slotsPlaylist.selectedSlot.id)
  console.log(media)

  await axios.post('https://apiv3.opentlv.com/api/v3/playlist/add/playlist/slot', {params: {playlist, slot_id: getState().slotsPlaylist.selectedSlot.id }})

  await dispatch(getSlot(getState().slotsPlaylist.selectedSlot.id))

//  await dispatch(getAllDatasPlaylistSlot(playlist.playlist))
  console.log("axios")
  return playlist
})

export const moveSlot = createAsyncThunk('appUsers/moveSlot', async (playlist, {  }) => {

  console.log(playlist)

  await axios.post('https://apiv3.opentlv.com/api/v3/playlist/move/slot', playlist)

//  await dispatch(getSlot(getState().slotsPlaylist.selectedSlot.id))
//  await dispatch(getAllDatasPlaylistSlot(playlist.playlist))
return playlist

})
export const addDownloaderToSlot = createAsyncThunk('appUsers/addDownloaderToSlot', async (playlist, { dispatch }) => {

  await axios.post('https://apiv3.opentlv.com/api/v3/playlist/add/schedule/slot', playlist)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))

  await dispatch(getAllDatasPlaylistSlot(playlist.playlist))

  return playlist
})


export const deletePlaylist = createAsyncThunk('appUsers/deletePlaylist', async (id, { dispatch, getState }) => {
  console.log("getState()")
  console.log(id)

  await axios.delete('https://apiv3.opentlv.com/api/v3/clients/playlist/delete', {params: {id}})
//  await dispatch(getData(getState().users.params))
    await dispatch(getDataClientsPlaylists(getState().playlists.params))

  return id
})

export const deleteSlot = createAsyncThunk('appUsers/deleteSlot', async (slot, { dispatch, getState }) => {
  console.log("getState()")
  console.log(slot.type)

  await axios.delete('https://apiv3.opentlv.com/api/v3/playlist/slot/delete', {params: {slot_id : slot.id, type:slot.type, playlist_id:getState().slotsPlaylist.selectedPlaylist.id}})
//  await dispatch(getData(getState().users.params))
    await dispatch(getAllDatasPlaylistSlot(getState().slotsPlaylist.selectedPlaylist.id))

  return id
})
export const deactivateSlot = createAsyncThunk('appUsers/deactivateSlot', async (slot, { dispatch, getState }) => {
  console.log("getState()")
  console.log(slot)

  await axios.post('https://apiv3.opentlv.com/api/v3/playlist/slot/deactivate', {params: {slot_id : slot.id, type:slot.type, playlist_id:getState().slotsPlaylist.selectedPlaylist.id}})
//  await dispatch(getData(getState().users.params))
    await dispatch(getAllDatasPlaylistSlot(getState().slotsPlaylist.selectedPlaylist.id))

  return id
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})


export const getAllDataSlotMedias = createAsyncThunk('appUsers/getAllDataSlotMedias', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/playlist/slot/medias/all', { params: { id} })
  return response.data
})

export const getDataSlotMedias = createAsyncThunk('appUsers/getDataSlotMedias', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/playlist/slot/medias/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})


export const getAllDataSlotOptions = createAsyncThunk('appUsers/getAllDataSlotOptions', async (id, {getState}) => {
  console.log(getState().slotsPlaylist.selectedPlaylist.type)
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/playlist/slot/options/all', { params: { id, playlist : getState().slotsPlaylist.selectedPlaylist} })
  return response.data
})

export const getDataSlotOptions = createAsyncThunk('appUsers/getDataSlotOptions', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/playlist/slot/options/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})
export const getPlaylistDownload = createAsyncThunk('appUsers/getPlaylistDownload', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/playlist/download', { params: { id} })
  return response.data
})

export const getMedialistDownload = createAsyncThunk('appUsers/getMedialistDownload', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/medialist/download', { params: { id} })
  return response.data
})


export const getAllDataSlotSchedules = createAsyncThunk('appUsers/getAllDataSlotSchedules', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/playlist/slot/schedules/all', { params: { id} })
  return response.data
})

export const getDataSlotSchedules = createAsyncThunk('appUsers/getDataSlotSchedules', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/playlist/slot/schedules/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const addDownloader = createAsyncThunk('appUsers/addDownloader', async (downloader, { dispatch, getState }) => {

////schedule.playlist_id = getState().slotsPlaylist.selectedSlot.id
  downloader.playlist_id = getState().slotsPlaylist.selectedPlaylist.id

  await axios.post('https://apiv3.opentlv.com/api/v3/playlist/add/downloader', downloader)
  //console.log(getState().slotsPlaylist)
  
  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))

  await dispatch(getAllDataSlotSchedules(playlist.slot_id))

  return schedule
})

export const addScheduleToSlot = createAsyncThunk('appUsers/addScheduleToSlot', async (schedule, { dispatch, getState }) => {

  schedule.slot_id = getState().slotsPlaylist.selectedSlot.id

  console.log(schedule)
  await axios.post('https://apiv3.opentlv.com/api/v3/playlist/add/schedule/slot', schedule)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))

  await dispatch(getAllDataSlotSchedules(getState().slotsPlaylist.selectedSlot.id))

  return schedule
})

export const deleteDownloader = createAsyncThunk('appUsers/deleteDownloader', async (id, { dispatch, getState }) => {

  console.log("deleteDownloader")
  console.log(id)
  
  await axios.delete('https://apiv3.opentlv.com/api/v3/playlist/delete/downloader/slot', {params: {id}})
  
    //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  
    await dispatch(getAllDataSlotSchedules(getState().slotsPlaylist.selectedSlot.id))
  
    return id
  })

  export const editSchedule = createAsyncThunk('appUsers/editSchedule', async (site, { dispatch, getState }) => {
    //site.id_site = getState().clients.selectedSite.id
    
    //console.log(getState().sitesClient)
  
    await axios.post('https://apiv3.opentlv.com/api/v3/site/schedule/edit', site)
  
    //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
   // await dispatch(getDataSchedulesSite(getState().schedulesSite.params))
    await dispatch(getAllDataSlotSchedules(getState().slotsPlaylist.selectedSlot.id))

    return site
  })
  

export const deleteSchedule = createAsyncThunk('appUsers/deleteSchedule', async (id, { dispatch, getState }) => {

console.log("deleteSchedule")
console.log(id)

await axios.delete('https://apiv3.opentlv.com/api/v3/playlist/delete/schedule/slot', {params: {id}})

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))

  await dispatch(getAllDataSlotSchedules(getState().slotsPlaylist.selectedSlot.id))

  return id
})


export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedPlaylist: null,
    downloader : [],
    selectedSlot: null,
    typeSlot: null,
    typePlaylist:null,
    options:[],
    totalOptions: 1,
    paramsOptions: {},
    allOptions: [],
    medias:[],
    totalMedias: 1,
    paramsMedias: {},
    allMedias: [],
    schedules:[],
    playlists:[],
    totalSchedules: 1,
    paramsSchedules: {},
    allSchedules: [],
    catalogs: []

  },

  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getUser.fulfilled, (state, action) => {
      state.selectedUser = action.payload
    })
    .addCase(getSlot.fulfilled, (state, action) => {
      state.selectedSlot = action.payload.slot
      state.typeSlot = action.payload.type
      state.options = action.payload.options
      state.typePlaylist = action.payload.typePlaylist
      state.selectedPlaylist = action.payload.playlist
      state.medias = action.payload.medias
      state.playlists = action.payload.playlists
      state.client  = action.payload.client
      state.groupe  = action.payload.groupe
    
    })

    .addCase(getEditSlot.fulfilled, (state, action) => {
      state.selectedSlot = action.payload.slot
      state.typeSlot = action.payload.type    
    })


    .addCase(getDataSlot.fulfilled, (state, action) => {
      state.medias = action.payload.medias
    })
    .addCase(getAllDatasPlaylistSlot.fulfilled, (state, action) => {
      state.data = action.payload.slots
      state.selectedPlaylist = action.payload.playlist
      state.downloader = action.payload.downloader
      state.catalogs = action.payload.catalogs
      state.client  = action.payload.client
      state.groupe = action.payload.groupe
      state.site = action.payload.site
      state.channel = action.payload.channel
    })

    .addCase(getAllDataSlotSchedules.fulfilled, (state, action) => {
      state.schedules = action.payload
    })

  }
})

export default appClientsSlice.reducer