// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { getAllCampagnes } from '../../support/store'

export const getAllMedias = createAsyncThunk('appUsers/getAllMedias', async () => {
  const response = await axios.get('https://apiv3.opentlv.com/creator/medias/all')
  return response.data
})

export const getDataMedias = createAsyncThunk('appUsers/getDataMedias', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/creator/medias/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})
export const getAllcatalogs = createAsyncThunk('appUsers/getAllcatalogs', async () => {
  const response = await axios.get('https://apiv3.opentlv.com/creator/catalogs/all')
  return response.data
})
export const getGroupe = createAsyncThunk('appUsers/getGroupe', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/groupes/groupe', { params: { id} })
  console.log(response.data)
  return response.data
})
export const editMedia = createAsyncThunk('appUsers/editMedia', async (obj, { dispatch, getState, rejectWithValue}) => {
  const formData = new FormData()
  console.log(getState().mediasInterface)
  formData.append('id', obj.id) 

  formData.append('id_channel', obj.channel_id) 
  formData.append('secteur', obj.secteur) 
  formData.append('catalog', obj.catalog) 
  formData.append('typologie', obj.typologie) 
  formData.append('langue', obj.langue) 
  
  try {
    console.log(obj)
  const response = await axios.post('https://apiv3.opentlv.com/interfacev2/edit/media', formData, {})
  await dispatch(getMedias(getState().mediasInterface.params))

  console.log(response)
  if (!response.data.success && !response.data.original) {

    console.log('la')
    console.log(response.data.success)

    return rejectWithValue(response.data)
}
       await dispatch(getMedias(getState().mediasInterface.params))
      return response.data
//  return response.data

} catch (error) {
console.log('error')
console.log(error)
throw rejectWithValue(error.message)
}
})
export const addmedia = createAsyncThunk('appUsers/addMedia', async (obj, { dispatch, getState, rejectWithValue}) => {
  const formData = new FormData()
  formData.append('file', obj.file) 
  formData.append('catalog', obj.catalog) 
  formData.append('langue', obj.langue) 
  formData.append('type', obj.type) 

  try {
    console.log(obj)
  const response = await axios.post('https://apiv3.opentlv.com/creator/medias/add', formData, {})

  console.log(response)
  if (!response.data.success && !response.data.original) {

    console.log('la')
    console.log(response.data.success)

    return rejectWithValue(response.data)
}
await dispatch(getDataMedias(getState().creatorMedias.params))
      return response.data
//  return response.data

} catch (error) {
console.log('error')
console.log(error)
throw rejectWithValue(error.message)
}
})
export const addData = createAsyncThunk('appUsers/addData', async (obj, { dispatch, rejectWithValue}) => {
    const formData = new FormData()
    formData.append('file', obj.file) 
    formData.append('spreed', obj.selectedSpreed) 

    try {
      console.log(obj)
    const response = await axios.post('https://apiv3.opentlv.com/creator/upload-data', formData, {})

    console.log(response)
    if (!response.data.success && !response.data.original) {

      console.log('la')
      console.log(response.data.success)

      return rejectWithValue(response.data)
  }
         await dispatch(getAllMedias(obj.channel_id))
        return response.data
  //  return response.data

} catch (error) {
  console.log('error')
  console.log(error)
  throw rejectWithValue(error.message)
}
  })
export const deleteMedia = createAsyncThunk('appUsers/deleteMedia', async (id, { dispatch, getState}) => {
  console.log("getState()")
  console.log(getState)

  await axios.delete('https://apiv3.opentlv.com/creator/medias/delete', {params: {id}})
//  await dispatch(getData(getState().users.params))
await dispatch(getDataMedias(getState().creatorMedias.params))

  return id
})

export const duplicateMedia = createAsyncThunk('appUsers/deleteMedia', async (id, { dispatch, getState}) => {
  console.log("getState()")
  console.log(getState)

  await axios.post('https://apiv3.opentlv.com/creator/medias/duplicate', {params: {id}})
//  await dispatch(getData(getState().users.params))
await dispatch(getDataMedias(getState().creatorMedias.params))

  return id
})
export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    catalogs: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllMedias.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getAllcatalogs.fulfilled, (state, action) => {
        state.catalogs = action.payload
      })
      .addCase(getDataMedias.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appClientsSlice.reducer
