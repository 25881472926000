// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'


export const getAllDataTickets = createAsyncThunk('appUsers/getTickets', async id  => {
    const response = await axios.get('https://apiv3.opentlv.com/monitoring/tickets', { params: { id} })
    return response.data
  })

  export const getDataTickets = createAsyncThunk('appUsers/getDataTickets', async params => {
    const response = await axios.get('https://apiv3.opentlv.com/api/monitoring/data', {params})
    console.log(response.data)
  
    return {
      paramsTickets,
      dataTickets: response.data.data,
      totalPages: response.data.total
    }
  })

  export const getAllDataLogs = createAsyncThunk('appUsers/getAllDataLogs', async id  => {
    const response = await axios.get('https://apiv3.opentlv.com/monitoring/logs', { params: { id} })
    return response.data
  })
  export const getDataLogs = createAsyncThunk('appUsers/getDataLogs', async params => {
    const response = await axios.get('https://apiv3.opentlv.com/monitoring/logs/data', {params})
  
    return {
      params,
      data: response.data.data,
      totalPages: response.data.total
    }
  })
  
export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    dataTickets: [],
    campagnes : [],
    tickets:[], 
    logs:[],
    data:[],
    total: 1,
    totalTickets: 1,
    params: {},
    paramsTickets: {},
    allData: [],
    allTickets: [],
    selectedUser: null,
    responseValidation : [],
    catalogs:[]
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataTickets.fulfilled, (state, action) => {
        state.tickets = action.payload
      })
      .addCase(getAllDataLogs.fulfilled, (state, action) => {
        state.logs = action.payload
      })
      .addCase(getDataLogs.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getDataTickets.fulfilled, (state, action) => {
        state.dataTickets = action.payload.dataTickets
        state.paramsTickets = action.payload.paramsTickets
        state.totalTickets = action.payload.totalPages
      })
  }
})

export default appUsersSlice.reducer
