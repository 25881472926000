// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataChannelsZone = createAsyncThunk('appUsers/getAllDataChannelsZone', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/zone/channels/all',  { params: { id} })
  return response.data
})

export const getDataChannelsZone = createAsyncThunk('appUsers/getDataChannelsZone', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/zone/channels/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})
export const addChannelToZone = createAsyncThunk('appUsers/addChannelToZone', async (option, { dispatch, getState }) => {
  //  option.client_id = getState()
    console.log("add Channel to Zone")
    console.log(option)


    console.log(getState().zoneChannels)
    option.zone_id = getState().zoneChannels.selectedZone.id
  
     await axios.post('https://apiv3.opentlv.com/api/v3/zone/addchanneltozone', option)

      await dispatch(getDataChannelsZone(getState().zoneChannels.params))
  
    //await d
  })
export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedSite: null,
    selectedClient: null,
    selectedZone: null

  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataChannelsZone.fulfilled, (state, action) => {
        state.allData = action.payload.channels
        state.selectedSite = action.payload.site
        state.selectedClient = action.payload.client
        state.selectedZone = action.payload.zone
      })
      .addCase(getDataChannelsZone.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appClientsSlice.reducer
