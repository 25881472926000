// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataConfigsClient = createAsyncThunk('appUsers/getAllDataConfigsClient', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/client/configs/all',  { params: { id} })
  return response.data
})

export const getDataConfigsClient = createAsyncThunk('appUsers/getDataConfigsClient', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/client/configs/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const addConfig = createAsyncThunk('appUsers/addConfig', async (channel, { dispatch, getState }) => {
  //channel.client_id = getState().sitesClient.params.id
    

  await axios.post('https://apiv3.opentlv.com/api/v3/client/addconfig', channel)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataConfigsClient(getState().configsClient.params))
  return channel
})
export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})

export const editConfig = createAsyncThunk('appUsers/editConfig', async (site, { dispatch, getState }) => {
  console.log("app")

//    site.id_site = getState().clients.selectedSite.id
  console.log(site)


  await axios.post('https://apiv3.opentlv.com/api/v3/client/editconfig', site)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataConfigurationZones(getState().configuration.params))
  return site
})

export const deleteConfig = createAsyncThunk('appUsers/deleteConfig', async (id, { dispatch, getState }) => {

  await axios.delete('https://apiv3.opentlv.com/api/v3/client/config/delete', {params: {id}})
//  await dispatch(getData(getState().users.params))
await dispatch(getDataConfigsClient(getState().configsClient.params))

  return id
})
export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataConfigsClient.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataConfigsClient.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appClientsSlice.reducer
