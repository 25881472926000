// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataPrivileges = createAsyncThunk('appUsers/getAllDataPrivileges', async id => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/user/privileges/all', { params: { id} })
  return response.data
})

export const getDataPrivileges = createAsyncThunk('appUsers/getDataPrivileges', async params => {
  const response = await axios.get('https://apiv3.opentlv.com/api/v3/user/privileges/data', { params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})
export const addPrivilgeToUser = createAsyncThunk('appUsers/addPrivilgeToUser', async (group, { dispatch, getState }) => {
  //  option.client_id = getState()
    console.log("addPrivilgeToGroup")
    console.log(getState().userPrivileges.params)
    
    group.user_id = getState().users.selectedUser.id
    
    
    await axios.post('https://apiv3.opentlv.com/api/v3/user/addprivilegetouser', group)
    await dispatch(getDataPrivileges(getState().userPrivileges.params))
  
    //await d
  })
export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
    },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getAllDataPrivileges.fulfilled, (state, action) => {
      state.allData = action.payload
    })
    .addCase(getDataPrivileges.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export default appUsersSlice.reducer
